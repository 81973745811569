import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactPage from "../components/contact"

const Contact = (props) => (
  <Layout>
    <SEO title="Contact Me" description="Please use my contact form on this site to get in touch or alternatively check out my Fiver gigs with reviews below!"/>
    <ContactPage/>
  </Layout>

)
export default Contact