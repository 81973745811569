import React from "react"
import { Col, Container, Row, Form } from "react-bootstrap"
import "./contact.scss"
import { motion } from "framer-motion"

const ContactForm = () => (
  <form action="https://formspree.io/jaykch@outlook.com" method="POST">
    <Form.Row>
      <Form.Group as={Col}>
        <Form.Control type="text" placeholder="Name" name="Name" required/>
      </Form.Group>
      <Form.Group as={Col}>
        <Form.Control type="email" placeholder="Email" name="Email" required/>
      </Form.Group>
    </Form.Row>
    <Form.Group>
      <Form.Control type="text" placeholder="Subject" name="Subject" required/>
    </Form.Group>
    <Form.Group>
      <Form.Control as="textarea" rows="5" placeholder="Message" name="Message" required/>
    </Form.Group>
    <motion.button type="submit" className="premium-button" whileTap={{ scale: 0.6}}>
      Send
    </motion.button>
  </form>
)

const ContactPage = () => {
  return (
    <motion.section id="contact-page" initial={{ scale: 0.5 }} animate={{ scale: 1 }}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6} className="text-container">
            <motion.div initial={{ scale: 0.5 }} animate={{ scale: 0.96}}>
            <h1>Contact Me</h1>
            <ContactForm/>
            </motion.div>
          </Col>
          <Col sm={12} md={6} className="map-container">
            <iframe title="location" src="https://snazzymaps.com/embed/18692" width="100%" height="100%"
                    frameBorder="none"/>
          </Col>
        </Row>
      </Container>
    </motion.section>
  )
}

export default ContactPage
